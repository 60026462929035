<template>
  <div class="container-fluid px-4" v-if="!isLoading">
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <h1 class="mt-4">My Saved Resumes</h1>
      </div>
      <div class="col-sm-6 col-lg-8 recruiter-assistance">
        <p class="resume-request mt-4">
          <button class="btn btn-large btn-secondary" @click="addGroup">
            <i class="bi bi-star-fill"></i> Add Folder
          </button>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <RzTable :headers=headers :table=tableData>
          <template #tableData>
            <tbody>
              <tr v-for="(group, index) in groups" :key="index">
                <td>
                  <router-link class="dashboard-link" :to="`/dashboard/groups/${group.id}`">
                    {{  group.name  }}
                  </router-link>
                </td>
                <td>{{  group.notes  }}</td>
                <td class="text-center">
                  <span>
                    <a class="btn btn-info" title="View group" @click="viewGroup(group)">
                      <span class="fa fa-edit"></span>
                    </a>&nbsp;
                    <a class="btn btn-info" title="Delete group" @click="deleteGroup(group)">
                      <span class="fa fa-trash"></span>
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </RzTable>
      </div>
    </div>
    <RzModal :title=modalTitle :size=modalSize ref="rzModal">
      <template v-if="isShowModal" #body>
        <RzGroupModal :selectedGroup=selectedGroup />
      </template>
    </RzModal>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

import ApiService from '@/services/ApiService'
import RzTable from '@/components/common/Table'
import RzModal from '@/components/common/Modal'
import RzGroupModal from '@/components/modal/Group'

export default {
  name: 'RzSavedResume',
  components: {
    RzTable,
    RzModal,
    RzGroupModal,
  },
  props: {
    msg: String
  },
  data() {
    return {
      isLoading: true,
      limit: 50,
      offset: 0,
      groups: [],
      // table
      headers: [
        {"name": "Name", "width": "30%", "class": "text-left"},
        {"name": "Notes", "width": "50%", "class": "text-left"},
        {"name": "Actions", "width": "20%", "class": "text-center"},
      ],
      tableData: {
        "class": "table table-responsive table-striped groups-table",
        "name": "groups-list"
      },
      // modal
      modalTitle: "",
      modalSize: "",
      isShowModal: false,
      selectedGroup: {},
    }
  },
  async beforeMount() {
    await this.fetchGroups();
    this.isLoading = false;
    this.loadSpinner({isLoading: this.isLoading, message: ""})
    
  },
  created() {
  },
  methods: {
    loadSpinner({isLoading, message}){
      this.isLoading = isLoading;
      this.$emit("loadSpinner", {isLoading: isLoading, message: message})
    },
    toggleModal(){
      this.isShowModal = true;
      const userModal = new Modal(this.$refs.rzModal.$refs.rzModal, {});
      userModal.show();
    },
    async closeModal(){
      this.isShowModal = false;
      Modal.getInstance(this.$refs.rzModal.$refs.rzModal)?.hide();
      await this.fetchGroups();
    },
    addGroup(){
      this.modalTitle = "Add Folder";
      this.toggleModal();
    },
    viewGroup(group){
      this.selectedGroup = group;
      this.modalTitle = "Edit Folder";
      this.toggleModal();
    },
    async deleteGroup(group){
      let hasError = true;
      if (confirm(`Are you sure you want to delete ${group.name} ?`)){
        this.isLoading = true;
        this.loadSpinner({isLoading: this.isLoading, message: "Deleting Folder..."})
        await ApiService
          .delete(`api/group/${group.id}`)
          .then(() => {
            hasError = false
          })
          .catch((error) => {
            console.log(error)
          })
        if (!hasError){
          await this.fetchGroups();
          this.isLoading = false;
          this.loadSpinner({isLoading: this.isLoading, message: ""})
        }
      }
    },
    async fetchGroups(){
      await ApiService
        .get(`api/group?is_active=true&limit=${this.limit}&offset=${this.offset}`)
        .then((response) => {
          this.groups = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    } 
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.recruiter-assistance{
  text-align: right;
}
#btnGroupAddon {
  width: fit-content;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
