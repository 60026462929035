<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="mt-4">Help</h1>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">
            If you have any questions on your account, billing questions or anything please use the form below to contact us or email us at <a href="mailto:support@therestaurantzone.com" class="dashboard-link">support@therestaurantzone.com</a>. If you’d like to upgrade, downgrade, reactivate or cancel an account, please go ahead and email us. We try to answer all inquiries within 24-48 hours or less. Thank you!
          </li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card shadow contact-card">
          <div class="card-header p-3 h4">
              Contact Us
          </div>
          <div class="card-body">
            <form role="form" class="row">
              <div class="form-group">
                <label class="form-control-label" for="form-group-input">Name *</label>
                <input type="text" class="form-control" id="form-group-input" name="name" required>
              </div>
              <div class="form-group">
                <label class="form-control-label" for="form-group-input">Email Address *</label>
                <input type="text" class="form-control" id="form-group-input" name="email">
              </div>
              <div class="form-group">
                <label class="form-control-label" for="form-group-input">Purpose of Request</label>
                <select class="form-control" id="reasonDropdown" name="regarding" required="">
                  <option value="Customer Support">Customer Support</option>
                  <option value="Technical Support">Technical Support</option>
                  <option value="Enhancement Request">Enhancement Request</option>
                </select>
              </div>
              <div class="form-group">
                <label class="form-control-label" for="form-group-input">Question/Feedback *</label>
                <textarea class="form-control" id="form-group-input" name="notes" rows="6" required></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary expand" for="form-group-input">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RzHelp',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact-card {
  background: #d0d4db;
  border: 1px solid #b1b4b8;
}
.form-group {
  margin-top: 10px;
}
</style>
