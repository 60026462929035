// Dummy translation service in case the app is ever internationalized
export default {
    install(app) {
      app.config.globalProperties.$t = this;
    },
  
    get(text) {
      if (window.gettext !== undefined) {
        return window.gettext(text);
      }
      return text;
    }, 
  
    get_lazy(text) {
      return function() {
        if (window.gettext !== undefined) {
          return window.gettext(text);
        }
        return text
      }
    }
  }
  