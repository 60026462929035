<template>
  <div class="rz-spinner" style="text-align: center;">
    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status" v-if="message == ''"> 
      <span class="visually-hidden">Loading...</span>
    </div>
    <button class="btn btn-info" type="button" v-if="message != ''">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="text-white">{{message}}...</span>
    </button>
  </div>
</template>
<script>
export default {
  name: "RzSpinner",
  props: {
    message: String
  },
  computed: {},
  methods: {}
};
</script>
<style scoped>
.rz-spinner{
  width: 100%;
  height: 100%;
  background: #000000a3;
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
}
</style>
  