import { createApp } from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Toasted from 'vue-toasted'
import  VueGoogleMaps from '@fawmi/vue-google-maps'

import ApiService from './services/ApiService.js'
import TranslationService from "./services/TranslationService"

import App from './App.vue'
import { createRouter } from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"

import "@fortawesome/fontawesome-free/css/all.min.css"

import "./assets/css/font.css"
import "./assets/css/main.css"
import "./assets/css/tables.css"
import "./assets/css/dashboard/dashboard.css"

const app = createApp(App);
app.config.globalProperties.apiUrl = process.env.VUE_APP_API_URL
app.config.globalProperties.publicUrl = process.env.BASE_URL

// Extend dayjs with relativeTime plugin
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

// Toasted workaround
const prevPrototype = app.prototype

app.prototype = {}

app.use(Toasted, {
  duration: 5000,
  position: 'top-right'
})

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCHPP77knf-zQlCDNEuCz8lWl6KtAChTQM',
    libraries: "places"
  },
})
Object.assign(app.config.globalProperties, app.prototype)

app.prototype = prevPrototype

// Axios and other services
app.use(VueAxios, axios).use(ApiService).use(TranslationService)

// Router
const router = createRouter(app, app.config.globalProperties.publicUrl)
app.use(router)

// Store
// const store = createStore(app)
// app.use(store)
window.appRoot = app.mount('#app')