<template>
  <RzResume />
</template>

<script>
import RzResume from '@/components/dashboard/ResumeDatabase'

export default {
  name: 'RzResumeView',
  components: {
    RzResume
  }
}
</script>