<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="mt-4">
          <router-link to="/dashboard/groups" class="dashboard-link">Back To Folders</router-link>
        </h3>
      </div>
    </div>
    <div class="row search-history">
      <div class="col-sm-12">
        <section class="search-history-title text-center">{{ group.name }} - Resumes</section>
        <RzTable :headers=headers :table=tableData >
          <template #tableData >
            <tbody v-if="savedResumes.length > 0 && !loading">
              <tr v-for="(savedResume, index) in savedResumes" :key="index">
                <td>{{  savedResume.resume.business_card?.name  }}</td>
                <td>
                  {{ savedResume.resume.address.city }} {{ savedResume.resume.address.state }} {{ savedResume.resume.address.country }}
                </td>
                <td class="text-center">
                  <button class="btn btn-info" title="View resume" @click="viewResume(savedResume.resume_id)">
                    <span class="fa fa-file-text"></span>
                  </button>&nbsp;
                  <button class="btn btn-info" title="Remove resume from folder" @click="deleteSavedResume(savedResume.id)">
                    <span class="fa fa-trash"></span>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="savedResumes.length == 0 || loading">
              <tr>
                <td :colspan="headers.length">
                  <div v-if="loading" class="text-center loading">
                    <i class="fa fa-spinner fa-spin"></i> Loading...
                  </div>
                  <div v-else>No saved resumes found in this folder.</div>
                </td>
              </tr>
            </tbody>
            <PaginationDisplay :record-length="savedResumes.length" :limit="searchLimit" :offset="searchOffset" :maxResults="-1" v-on:next="navigate(1)" v-on:prev="navigate(-1)" />
          </template>
        </RzTable>
      </div> 
    </div>
    <RzModal :title=modalTitle :size=modalSize ref="rzModal">
      <template v-if="isShowModal" #body>
        <RzResumeViewModal v-if="modalTitle == 'View Resume'" :selectedResume=selectedResume :activeGroups=activeGroups />
      </template>
    </RzModal>
  </div>
</template>

<style scoped>
.loading i {
  color: #007bff;
}
</style>
    
<script>
import { Modal } from 'bootstrap'

import ApiService from '@/services/ApiService'
import RzModal from '@/components/common/Modal'
import RzTable from '@/components/common/Table'
import RzResumeViewModal from '@/components/modal/ResumeView'

import PaginationDisplay from '@/components/common/PaginationDisplay'

export default {
  name: 'RzSavedResumeDetail',
  components: {
    RzModal,
    RzTable,
    RzResumeViewModal,    
    PaginationDisplay
  },
  props: {},
  data() {
    return {
      group: {},
      savedResumes: [],
      selectedResume: {},
      activeGroups: [],
      tableData: {
        "class": "table table-responsive table-striped accounts-table",
        "name": "accounts-list"
      },
      headers: [
        {"name": "Name", "width": "35%", "class": "text-left"},
        {"name": "Location", "width": "40%", "class": "text-left"},
        {"name": "Actions", "width": "15%", "class": "text-center"},
      ],
      // modal
      modalTitle: "",
      isShowModal: false,
      modalSize: "modal-xl",

      loading: false,
      searchOffset: 0,
      searchLimit: 50
    }
  },
  async beforeMount() {
    await this.fetchGroup(this.$route.params.id)
    await this.fetchSavedResumes(this.$route.params.id);
  },
  methods: {
    showModal(){
      this.isShowModal = true;
      var theModal = new Modal(this.$refs.rzModal.$refs.rzModal, {})
      theModal.show();
    },
    closeModal(){
      this.isShowModal = false;
      Modal.getInstance(this.$refs.rzModal.$refs.rzModal)?.hide();
    },
    async fetchGroup(groupId){
      await ApiService
        .get(`api/group/${groupId}`)
        .then((response) => {
          this.group = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getResumeById(resumeId){
      await ApiService
        .get(`api/resume/${resumeId}`)
        .then((response) => {
          this.selectedResume = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchSavedResumes(groupId){
      this.loading = true;
      ApiService
        .get('api/resume/saved', {
          resume_group_id: groupId,
          // NOTE: Maybe we should remove this full parameter?
          full: true,
          limit: this.searchLimit,
          offset: this.searchOffset
        })
        .then((response) => {
          this.savedResumes = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          this.$toasted.error("Failed to fetch saved resumes.")
        })
    },
    // async editResume(resumeId){
    //   await this.getResumeById(resumeId);
    //   this.isShowModal = true;
    //   this.modalTitle = "Edit Resume";
    //   this.showModal();
    // },
    async getActiveGroups(){
      await ApiService
        .get("api/group?is_active=true")
        .then((response) => {
          this.activeGroups = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async viewResume(resumeId){
      await this.getResumeById(resumeId);
      await this.getActiveGroups();
      this.isShowModal = true;
      this.modalTitle = "View Resume";
      this.showModal();
    },
    async deleteSavedResume(resumeId){
      if (confirm(`Are you sure want to delete saved resume with id ${resumeId}?`)){
        await ApiService
          .delete(`api/resume/saved/${resumeId}`)
          .then(() => {})
          .catch((error) => {
            console.error(error)
          })
      }
    },
    navigate(direction){
      this.searchOffset += this.searchLimit * direction
      if (this.searchOffset < 0){
        this.searchOffset = 0
      }
      this.fetchSavedResumes(this.$route.params.id)
    }
  }
}
</script>
<style scoped>
.search-history-title{
  background: #222d32;
  padding: 15px 15px;
  color: white;
  font-weight: bold;
}
.search-history{
  margin-top: 20px;
}
</style>
    