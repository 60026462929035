<template>
  <!-- Modal -->
  <form @submit.prevent="addToAccount()">
    <div class="mb-3">
      <label for="User" class="form-label">User:</label>
      <select class="form-select form-select-lg mb-3" style="height: calc(100vh - 300px);" multiple aria-label=".form-select-lg example" v-model="accountUsers">
        <option v-for="(user, index) in users" :key=index :value=user.id>
          {{ user.email }}
        </option>
      </select>
      <table class="table">
        <PaginationDisplay :record-length="users.length" :limit="searchLimit" :offset="searchOffset" :maxResults="-1" v-on:next="navigate(1)" v-on:prev="navigate(-1)" />
      </table>
    </div>
    <div class="mb-3 text-center" v-if="accountUsers.length > 0">
      <button type="submit" class="btn btn-info">Add To Account</button>
    </div>
  </form>
</template>
<script>
import ApiService from '@/services/ApiService'
import PaginationDisplay from '@/components/common/PaginationDisplay'

export default {
  name: 'RzAccountUserModal',
  components: {
    PaginationDisplay
  },
  props: {
    account: Object
  },
  beforeMount(){
    this.getUsers();
  },
  created() {},
  update(){
  },
  data(){
    return {
      users: [],
      accountUsers: [],

      searchLimit: 50,
      searchOffset: 0,
    }
  },
  methods: {
    getUsers(){
      ApiService
        .get("api/user", {limit: this.searchLimit, offset: this.searchOffset})
        .then((response) => {
          this.users = response.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    addToAccount(){
      this.accountUsers.map((userId) => {
        const payload = {"account_id": this.account.id, "user_id": userId}
        ApiService
          .post("api/account/user",payload)
          .then(() => {
            this.$emit('saved')
            this.$toasted.success('User added to account')
          })
          .catch((error) => {
            console.error(error);
            let msg = error.response.data !== undefined && error.response.data.message !== undefined ? error.response.data.message : 'Failed to add user to account'
            this.$toasted.error(msg)
          })
      })
    },
    navigate(direction){
      this.searchOffset += this.searchLimit * direction
      if (this.searchOffset < 0){
        this.searchOffset = 0
      }
      this.getUsers()
    }
  }
  
}
</script>
<style scoped></style>
