<template>
  <div class="container-fluid px-4" v-if="!isLoading">  
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <h1 class="mt-4">Accounts</h1>
      </div>
      <div class="col-sm-6 col-lg-8 recruiter-assistance">
        <p class="resume-request mt-4">
          <a class="btn btn-large btn-secondary" @click="addAccount()">
            <i class="bi bi-folder-fill"></i> Add Account
          </a>
        </p>
      </div>
    </div>
    <div class="row" v-if="hasError">
      <div class="col-sm-12">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>{{ error.code }}</strong> {{ error.message }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <RzTable :headers=headers :table=tableData >
          <template #tableData>
            <tbody>
              <tr v-for="(account, index) in accounts" :key="index">
                <td>
                  <router-link :to="`/admin/account/${account.id}/users`" class="dashboard-link">
                    {{  account.account_name  }}
                  </router-link>
                </td>
                <td>{{  getAccountClassById(account.account_class) }}</td>
                <td>{{  formatDateTimeSince(account.start_datetime)  }}</td>
                <td class="text-center">
                  <i v-if="account.is_active" class="fa fa-square-check"></i>
                  <i v-if="!account.is_active" class="fa fa-square-x"></i>
                </td>
                <td class="text-center">
                  <span >
                    <router-link class="btn btn-info" title="Search History" :to="`/admin/account/${account.id}/history`">
                      <span class="fa fa-search"> </span>
                    </router-link>&nbsp;
                    <button class="btn btn-info" title="Edit" @click="editAccount(account)"> <span class="fa fa-edit"> </span> </button>&nbsp;
                    <button class="btn btn-info" title="Delete" @click="deleteAccount(account)"> <span class="fa fa-trash"> </span> </button>
                  </span>
                </td>
              </tr>
            </tbody>
            <PaginationDisplay :record-length="accounts.length" :limit="searchLimit" :offset="searchOffset" :max-results="-1" v-on:next="navigatePage(1)" v-on:prev="navigatePage(-1)" />
          </template>
        </RzTable>
      </div>
    </div>
    <RzModal :title=modalTitle :size=modalSize ref="rzModal">
      <template v-if="isShowModal" #body>
        <RzAccountModal :accountClasses=accountClasses :selectedAccount=selectedAccount v-on:saved="onAccountSaved"/>
      </template>
    </RzModal>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import { Modal } from 'bootstrap'

import ApiService from '@/services/ApiService'
import RzTable from '@/components/common/Table'
import RzModal from '@/components/common/Modal'
import RzAccountModal from '@/components/modal/Account'
import PaginationDisplay from '@/components/common/PaginationDisplay'

export default {
  name: 'RzAccount',
  components: {
    RzTable,
    RzModal,
    RzAccountModal,
    PaginationDisplay
  },
  props: {
    msg: String
  },
  data() {
    return {
      isLoading: true,
      hasError: false,
      error: {
        code: "",
        message: "",
      },
      searchLimit: 50,
      searchOffset: 0,
      accounts: [],
      accountClasses: [],
      tableData: {
        "class": "table table-responsive table-striped accounts-table",
        "name": "accounts-list"
      },
      headers: [
        {"name": "Name", "width": "30%", "class": "text-left"},
        {"name": "Class", "width": "20%", "class": "text-left"},
        {"name": "Start Date", "width": "20%", "class": "text-left"},
        {"name": "Active	", "width": "10%", "class": "text-center"},
        {"name": "Actions", "width": "20%", "class": "text-center"},
      ],
      selectedAccount: {},
      // modal data
      isShowModal: false,
      modalTitle: "",
      modalSize: "",
    }
  },
  async beforeMount() {
    await this.fetchAccounts();
    await this.fetchAccountClasses();
    this.isLoading = false;
    this.loadSpinner({isLoading: this.isLoading, message: ""})

  },
  methods: {
    loadSpinner({isLoading, message}){
      this.isLoading = isLoading;
      this.$emit("loadSpinner", {isLoading: isLoading, message: message})
    },
    async fetchAccounts(){      
      await ApiService
        .get("api/account", {
          limit: this.searchLimit,
          offset: this.searchOffset
        })
        .then((response) => {
          this.accounts = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async fetchAccountClasses(){
      await ApiService
        .get("api/account/class")
        .then((response) => {
          this.accountClasses = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getAccountClassById(accountClassId){
      const accountClass = this.accountClasses.filter((accountClass) => {
        return accountClass.id == accountClassId
      })
      if (accountClass.length > 0){
        return accountClass[0].notes;
      }else{
        return 'N/A'
      }
    },
    showModal(){
      this.isShowModal = true;
      var theModal = new Modal(this.$refs.rzModal.$refs.rzModal, {})
      theModal.show();
    },
    closeModal(){
      this.isShowModal = false;
      Modal.getInstance(this.$refs.rzModal.$refs.rzModal)?.hide();
    },
    addAccount(){
      this.selectedAccount = {};
      this.modalTitle = "Add Account";
      this.showModal();
    },
    editAccount(account){
      this.selectedAccount = account;
      this.modalTitle = "Edit Account";
      this.showModal();
    },
    async deleteAccount(account){
      if (confirm(`Are you sure you want to delete user ${account.account_name}?`)){
        this.isLoading = true;
        this.loadSpinner({isLoading: this.isLoading, message: "Deleting Account..."})
        await ApiService.delete(`api/account/${account.id}`)
          .then(() => {})
          .catch((error) => {
            this.hasError = true;
            this.error = {
              code: error.code,
              message: error.message,
            }
          })
        
        if (!this.hasError){
          await this.fetchAccounts();
          await this.fetchAccountClasses();
        }
        this.isLoading = false;
        this.loadSpinner({isLoading: this.isLoading, message: ""})
      }
    },
    onAccountSaved(){
      this.fetchAccounts();
    },
    navigatePage(direction){
      this.searchOffset += direction * this.searchLimit;
      if (this.searchOffset < 0){
        this.searchOffset = 0;
      }
      this.fetchAccounts();
    },
    formatDateTimeSince(dt) {
      return dayjs(dt).fromNow();
    }
  }
}
</script>
<style scoped>
.recruiter-assistance{
  text-align: right;
}
#btnGroupAddon {
  width: fit-content;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
i.fa, i.fa:hover{
  color: #0dcaf0;
}
</style>
