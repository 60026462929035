<template>
  <div class="container-fluid px-4" v-if="!isLoading">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="mt-4">
          <router-link to="/admin/account" class="dashboard-link">Back To Accounts</router-link>
        </h3>
      </div>
    </div>
    <div class="row search-history">
      <div class="col-sm-12">
        <section class="search-history-title text-center">{{ account.account_name }} - Search History</section>
        <RzTable :headers=headers :table=tableData >
          <template #tableData >
            <tbody v-if="searchHistory.length > 0">
              <tr v-for="(history, index) in searchHistory" :key="index">
                <td>{{  history.search_params.query  }}</td>
                <td>{{  history.search_params.filters.location?.address }}</td>
                <td class="text-center">{{  history.search_params.filters.location?.distance }}</td>
                <td class="text-center">{{  history.search_datetime  }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="searchHistory.length == 0">
              <tr>
                <td :colspan="headers.length">No search history found for account.</td>
              </tr>
            </tbody>
          </template>
        </RzTable>
      </div> 
    </div>
  </div>
</template>
  
<script>  
import ApiService from '@/services/ApiService'
import RzTable from '@/components/common/Table'

export default {
  name: 'RzAccountSearchHistory',
  components: {
    RzTable,
  },
  props: {},
  data() {
    return {
      isLoading: true,
      account: {},
      searchHistory: [],
      tableData: {
        "class": "table table-responsive table-striped accounts-table",
        "name": "accounts-list"
      },
      headers: [
        {"name": "Keyword", "width": "40%", "class": "text-left"},
        {"name": "Location", "width": "25%", "class": "text-left"},
        {"name": "Distance", "width": "15%", "class": "text-center"},
        {"name": "Datetime", "width": "20%", "class": "text-center"},
      ],
    }
  },
  async beforeMount() {
    await this.fetchAccount(this.$route.params.id)
    await this.fetchAccountSearchHistory(this.$route.params.id);
    this.isLoading = false;
    this.loadSpinner({isLoading: this.isLoading, message: ""})
  },
  methods: {
    loadSpinner({isLoading, message}){
      this.isLoading = isLoading;
      this.$emit("loadSpinner", {isLoading: isLoading, message: message})
    },
    async fetchAccount(accountId){
      await ApiService
        .get(`api/account/${accountId}`)
        .then((response) => {
          this.account = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchAccountSearchHistory(accountId){
      await ApiService
        .get(`api/history?account_id=${accountId}&limit=50&offset=0`)
        .then((response) => {
          this.searchHistory = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.search-history-title{
  background: #222d32;
  padding: 15px 15px;
  color: white;
  font-weight: bold;
}
.search-history{
  margin-top: 20px;
}
</style>
  