<template>
  <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
    <div class="sb-sidenav-menu">
      <div class="nav">
        <router-link class="nav-link" to="/dashboard/how-it-works" active-class="active">
          <div class="sb-nav-link-icon text-center">
            <i class="fas fa-exclamation"></i>
          </div>
          RZ How it Works
        </router-link>
        <router-link class="nav-link" to="/dashboard/premium-job-ads" active-class="active">
          <div class="sb-nav-link-icon">
            <i class="fas fa-pencil-square"></i>
          </div>
          RZ HireNow - Recruiter Assistance
        </router-link>
        <router-link class="nav-link" to="/dashboard/resume" active-class="active">
          <div class="sb-nav-link-icon"><i class="fas fa-search"></i></div>
          RZ Resume Database Search
        </router-link>
        <router-link class="nav-link" to="/dashboard/groups" active-class="active">
          <div class="sb-nav-link-icon"><i class="fas fa-star"></i></div>
          My Saved RZ Resumes
        </router-link>
        <router-link class="nav-link" to="/dashboard/background-check" active-class="active">
          <div class="sb-nav-link-icon"><i class="fas fa-address-card"></i></div>
          RZ Background Check
        </router-link>
        <router-link class="nav-link" to="/dashboard/hard-to-fill" active-class="active">
          <div class="sb-nav-link-icon"><i class="fas fa-user-plus"></i></div>
          RZ Direct: Hard-to-Fill & Urgent Positions?
          <span class="small new">NEW!</span>
        </router-link>
        <router-link class="nav-link" to="/admin/account" active-class="active" v-if="$root.isSuperAdmin()">
          <div class="sb-nav-link-icon"><i class="fas fa-folder"></i></div>
          Accounts
        </router-link>
        <router-link class="nav-link" to="/admin/user" active-class="active" v-if="$root.isSuperAdmin()">
          <div class="sb-nav-link-icon"><i class="fas fa-user"></i></div>
          Users
        </router-link>
        <router-link class="nav-link" to="/dashboard/help" active-class="active">
          <div class="sb-nav-link-icon"><i class="fas fa-question"></i></div>
          Help
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "RzSideBar",
  computed: {},
  props: ['user'],
  methods: {
    isSuperAdmin() {
      if (this.user !== null && this.user.role !== undefined) {
        return this.user.role.super_admin;
      }
      return false;
    }
  }
};
</script>
<style scoped>
#sidenavAccordion{
  background-color: #1f292d !important;
}
.sb-nav-link-icon i{
  width: 20px !important;
  color: #23acd3 !important;
}
.nav a {
  border-right: 10px solid transparent;
  display: block;
  padding: 12px 5px 12px 15px;
  cursor: pointer;
}
.new {
  color: #f66;
  font-weight: 700;
}
</style>
