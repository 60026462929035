<template>
  <RzDirectHire/>
</template>

<script>
import RzDirectHire from '@/components/dashboard/DirectHire'

export default {
  name: 'RzDirectHireView',
  components: {
    RzDirectHire
  }
}
</script>