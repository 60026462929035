import axios from "axios";

export default {
  authToken: null,
  apiUrl: null,

  install(app) {
    this.created(app.config.globalProperties.apiUrl ? app.config.globalProperties.apiUrl : process.env.VUE_APP_API_URL);
    app.config.globalProperties.$api = this;
  },
  data: {
    client: null,
    authToken: null,
    organizationId: null,
    apiUrl: null,
    initialized: false
  },
  created: function(apiUrl) {
    this.apiUrl = apiUrl;
    this.updateClient();
  },
  updateClient: function() {
    const headers = {};
    if (this.authToken) {
      headers['Authorization'] = this.authToken.type + ' ' + this.authToken.token;
    }
    this.client = axios.create({
      headers
    });
  },
  createAxiosInstance: function(opts = {}) {
    return axios.create(opts);
  },

  post: function(path, data, config) {
    return this.client.post(this.apiUrl + path, data === undefined ? {} : data, config !== undefined ? config : config);
  },
  put: function(path, data) {
    console.warn('Put used for ' + path + ' .. did you mean patch?');
    return this.client.put(this.apiUrl + path, data === undefined ? {} : data);
  },
  patch: function(path, data, config) {
    return this.client.patch(this.apiUrl + path, data === undefined ? {} : data, config !== undefined ? config : config);
  },
  delete: function(path, data) {
    return this.client.delete(this.apiUrl + path, data === undefined ? {} : data);
  },
  get: function(path, data) {
    const res = this.client.get(this.apiUrl + path, {
      params: data === undefined ? {} : data,
    });
    return res;
  },
  controllerGet: function(path, data) {
    const controller = new AbortController();
    const promise = this.client.get(this.apiUrl + path, {
      params: data === undefined ? {} : data,
      signal: controller.signal
    });
    return {
      promise,
      controller
    }
  },
  // Authentication and stored tokens
  isAuthenticated: function() {
    return this.authToken != null;
  },
  setAuthenticationToken: function() {
    let token = JSON.parse(localStorage.getItem("rztoken"))
    if (!token){
      return;
    }
    this.authToken = token;
    if (!this.authToken.type) {
      this.authToken.type = 'Token';
    }
    this.updateClient();
  },
  validateAuthenticationToken: function(token) {
    if (!token.type) {
      token.type = 'Token';
    }
    let client = axios.create({
      headers: {
        'Authorization': token.type + ' ' + token.token
      }
    });

    return client.get(this.apiUrl + 'api/user/current');
  },
  getCurrentUser() {
    return this.client.get(this.apiUrl + 'api/user/current');
  }
}
