<template>
  <RzSavedResumeDetail />
</template>
  
<script>
import RzSavedResumeDetail from '@/components/dashboard/saved-resume/SavedResumeDetail'

export default {
  name: 'RzSavedResumeDetailView',
  components: {
    RzSavedResumeDetail
  },
  mounted(){}
}
</script>