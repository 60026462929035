<template>
  <RzLogin />
</template>
<script>
import RzLogin from '@/components/auth/Login'

export default {
  name: 'RzLoginView',
  components: {
    RzLogin
  }
}
</script>

