<template>
  <RzAccountSearchHistory />
</template>
  
<script>
import RzAccountSearchHistory from '@/components/admin/accounts/AccountSearchHistory'

export default {
  name: 'RzAccountSearchHistoryView',
  components: {
    RzAccountSearchHistory
  }
}
</script>