<template>
  <RzUser />
</template>

<script>
import RzUser from '@/components/admin/users/User'

export default {
  name: 'RzUserView',
  components: {
    RzUser
  }
}
</script>