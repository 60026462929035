<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-sm-7">
        <h1 class="mt-4">Run Background Check</h1>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">
            <i>Run a background check on your candidates to check: criminal & civil records, social media profiles, contact information history, career history, associated people, bankruptcies, and more! Please note, each report costs $24.99 and will arrive within 24-48 hours.</i>
          </li>
        </ol>
        <p class="text-center start-hiring-now">
          <a
            class="btn btn-xlarge btn-primary btn-padded"
            target="_blank" href="https://therestaurantzone.wufoo.com/forms/pdeld900s77zfd/"
          >Run Background Check</a>
        </p>
      </div>
      <div class="col-sm-5 hiring-image">
        <img class="img-fluid" src="@/assets/images/wufoorms/background-check.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RzHireNow',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.start-hiring-now {
  margin-top: 5%;
}
.hiring-image {
  margin-top: 5%;
}
</style>
