<template>
  <!-- Modal -->
  <form @submit.prevent="saveUser">
    <div class="mb-3">
      <label for="firstName" class="form-label">First Name <span class="required">*</span></label>
      <input
        type="text" name="first_name" class="form-control"
        v-model="user.first_name" placeholder="First Name" required>
      <div class="alert alert-danger" v-if="getFormError('first_name')">{{ getFormError('first_name') }}</div>
    </div>
    <div class="mb-3">
      <label for="lastName" class="form-label">Last Name <span class="required">*</span></label>
      <input
        type="text" name="last_name" class="form-control"
        v-model="user.last_name" placeholder="Last Name" required>
      <div class="alert alert-danger" v-if="getFormError('last_name')">{{ getFormError('last_name') }}</div>
    </div>
    <div class="mb-3">
      <label for="emailAddress" class="form-label">Email Address <span class="required">*</span></label>
      <input
        type="email" name="email" class="form-control"
        v-model="user.email" placeholder="Email Address" required>
      <div class="alert alert-danger" v-if="getFormError('email')">{{ getFormError('email') }}</div>
    </div>
    <div class="mb-3">
      <label for="phoneNumber" class="form-label">Phone Number <span class="required">*</span></label>
      <input
        type="text" name="phone_number" class="form-control" maxlength="14" minlength="14"
        v-model="user.phone" placeholder="Phone Number" required>
      <div class="alert alert-danger" v-if="getFormError('phone')">{{ getFormError('phone') }}</div>
    </div>
    <div class="mb-3" v-if="user.role !== undefined">
      <label for="Role" class="form-label">Role:</label>
      <select class="form-select" name="role" v-model="user.role.id">
        <option value="" disabled selected>Select A Role:</option>
        <option v-for="(role, index) in roles" :key=index :value=role.id>{{ role.name }}</option>
      </select>
      <div class="alert alert-danger" v-if="getFormError('role')">{{ getFormError('role') }}</div>
    </div>
    <div class="mb-3 form-check" v-if="user.is_active !== undefined">
      <input type="checkbox" class="form-check-input" name="is_active" v-model="user.is_active">
      <label class="form-check-label" for="isActive">Is Active</label>
      <div class="alert alert-danger" v-if="getFormError('is_active')">{{ getFormError('is_active') }}</div>
    </div>
    <div class="mb-3" v-if="isSetPassword">
      <label for="password" class="form-label">Password <span class="required">*</span></label>
      <input 
        type="password" name="password" class="form-control"
        v-model="user.password" placeholder="Password" required>
      <div class="alert alert-danger" v-if="getFormError('password')">{{ getFormError('password') }}</div>
    </div>
    <div class="mb-3" v-if="isSetPassword">
      <label for="password" class="form-label">Password Confirmation <span class="required">*</span></label>
      <input
        type="password" name="confirm_password" class="form-control"
        v-model="user.passwordConfirm" placeholder="Password Confirmation" required>
      <div class="alert alert-danger" v-if="getFormError('passwordConfirm')">{{ getFormError('passwordConfirm') }}</div>
    </div>
    <div class="mb-3">
      <span class="float-start">
        <button type="submit" class="btn btn-info">Submit</button>
        <button v-if="ownProfile" class="btn btn-outline-danger" style="margin-left: 8px;" v-on:click.prevent="openClientFeedbackForm()">Pause/Cancel</button>
      </span>
      <span class="float-end">
        <button type="button" class="btn btn-info" @click="isSetPassword = !isSetPassword">
          <span v-if="!isSetPassword"><i class="fa fa-lock"></i>&nbsp;Set Password</span>
          <span v-if="isSetPassword"><i class="fa fa-lock-open"></i>&nbsp;Unset Password</span>
        </button>
      </span>
    </div>
  </form>
</template>
<script>
import { reactive } from "vue";
import ApiService from '@/services/ApiService'

import ModalFormMixin from "@/mixins/ModalFormMixin";


export default {
  name: 'RzUserModal',
  components: {},
  mixins: [ModalFormMixin],
  props: {
    roles: Array,
    selectedUser: Object,
    ownProfile: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (Object.keys(this.selectedUser).length > 0){
      this.user = {
        id: this.selectedUser.id,
        first_name: this.selectedUser.first_name,
        last_name: this.selectedUser.last_name,
        email: this.selectedUser.email,
        phone: this.selectedUser.phone,
        role: {
          id: this.selectedUser.role.id,
        },
        is_active: this.selectedUser.is_active,
        password: "",
        passwordConfirm: ""
      }

      if (this.ownProfile){
        // Remove role and is_active fields from user object
        delete this.user.role
        delete this.user.is_active
      }
    }

  },
  data(){
    return {
      isSetPassword: false,
      user: reactive({
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        role: {
          id: ""
        },
        is_active: false,
        password: "",
        passwordConfirm: ""
      })
    }
  },
  methods: {
    saveUser(){
      if (this.formProcessing){
        return
      }
      this.formProcessing = true
      this.formErrors = {}

      let saveData = Object.assign({}, this.user)
      if (!this.isSetPassword){
        delete saveData.password
        delete saveData.passwordConfirm
      }

      if (this.user.id == ""){
        delete saveData.id
        ApiService
          .post("api/user", saveData)
          .then(this.onSaveSuccess)
          .catch(this.onSaveError)
      } else {
        ApiService
          .put(`api/user/${this.user.id}`, saveData)
          .then(this.onSaveSuccess)
          .catch(this.onSaveError)
      } 
    },
    openClientFeedbackForm() {
      window.open('https://therestaurantzone.wufoo.com/forms/cancellation-feedback-form', '_blank');
    }
  }
  
}
</script>
<style scoped>
.fa, .fa:hover {
  color: white;
}
</style>
