  <template>
    <!-- Modal -->
    <div
      class="modal fade" id="rzModal" data-bs-backdrop="static" data-bs-keyboard="false"
      tabindex="-1" aria-labelledby="addUserModal" aria-hidden="true" ref="rzModal">
      <div :class="'modal-dialog ' + size">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ title }}</h1>
            <!-- data-bs-dismiss="modal" aria-label="Close" -->
            <button type="button" class="btn-close btn-close-white" @click="closeModal"></button>
          </div>
          <div class="rz-spinner" style="text-align: center;" v-if="isLoading">
              <div class="spinner" style="width: 3rem; height: 3rem;" role="status" v-if="message == ''"> 
                <span class="visually-hidden">Loading...</span>
              </div>
              <button class="btn btn-info" type="button" v-if="message != ''">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="text-white">Adding Account...</span>
              </button>
            </div>
          <div class="modal-body" v-if="!isLoading">
            <slot name="body" @load-modal-spinner="loadSpinner"/>
          </div>
          
          <!-- <div class="modal-body">
            
          </div> -->
        </div>
      </div>
    </div>
  </template>

  <script>

  export default {
    name: 'RzModal',
    components: {},
    props: {
      title: String,
      size: String,
    },
    data(){
      return {
        modalSize: "",
        isLoading: false,
        message: "",
      }
    },
    methods: {
      loadSpinner({isLoading, message}){
        this.isLoading = isLoading;
        this.message = message;
        // this.$parent.loadSpinner({isLoading, message})
      },
      closeModal(){
        this.$parent.closeModal()
      }
    }
    
  }
  </script>
  <style scoped>
  .rz-spinner{
    height: 300px;
    background: #000000a3;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
  }
  </style>