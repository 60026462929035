<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="mt-4">RZ Direct Hire</h1>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">
            Can't fill a role or have an urgent opening? Work with our RZ Direct Hire team which is composed of very experienced Sr. Recruiters (who have 10+ years experience) in full-cycle recruiting in this industry & for our clients. They’ll handle the entire search process for you and they only get paid once a candidate is hired. Which means, guaranteed results for you!
          </li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-7">
        <p><b>What is RZ DirectHire and how does it work?</b></p>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">
            RZ DirectHire is an alternative solution for clients who are unable to fill a position using our software & needs to take a more aggressive approach. With RZ DirectHire we will source the candidates, do phone/video interviews, pre-screen, background check, help negotiate offer letters and more! We’ll help you through every single step of the way.
          </li>
        </ol>
        <p>
          <a
            class="btn btn-xlarge btn-primary btn-padded"
            target="_blank" href="https://therestaurantzone.wufoo.com/forms/z1nr1s5q1av98e7/"
          >Get Started</a>
        </p>
        <p>
          <small class="form-text text-muted" id="emailHelp">
            Enter coupon RZPRIME at checkout and get $500 OFF. Exclusive for RZ subscribers only.
          </small>
        </p>
        <div class="easy-steps">
          <p>It’s simple, quick and easy.</p>
          <ul class="basicList mb-4">
            <li>Step 1: Click the “Get Started Button” and enter your basic information</li>
            <li>Step 2: Enter your job orders and details on the jobs that are tough to fill or urgent to fill</li>
            <li>Step 3: Agree to the terms of service, enter coupon code: RZPRIME for a special discount on pricing ($500 off this service).</li>
            <li>Step 4: Connect with a Dedicated RZ Sr. Recruiter to discuss any further details on the search</li>
            <li>Step 5: Receive qualified candidates right away and start hiring right away!</li>
          </ul>
        </div>
      </div>
      <div class="col-sm-5 hiring-image">
        <img class="img-fluid" src="@/assets/images/wufoorms/direct-hire.jpg">
      </div>
    </div>
    <div class="row rz-hire-info">
      <div class="col-sm-7">
      <p>RZ Direct Hire taps into our inhouse network of RZ Sr. recruiters.
        All of RZ's sr. recruiters are highly skilled, experienced sr. recruiters who solely recruit within the restaurant &amp; hospitality industry. Our team of sr. recruiters are always screening &amp; connecting with thousands of hospitality industry candidates daily (for our customers), all across the US &amp; Canada! RZ DirectHire gives you instant access to some of the best and brightest top Management/Executives, Culinary Talent, Corporate-Office and Hourly Staff. Many of whom are open to new job opportunities when they get a call from us!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RzDirectHire',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.easy-steps, .rz-hire-info{
  color: #6c757d;
}
</style>
