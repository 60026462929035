<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-sm-12">
        <h1 class="mt-4">RZ - How It Works</h1>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">Please watch the video before using the software</li>
        </ol>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <div class="ratio ratio-21x9">
            <iframe
              src="https://www.youtube.com/embed/d3-djMPVngY" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'RzDashboard',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
