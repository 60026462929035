<template>
  <div>
    <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <a class="navbar-brand ps-3 logo" href="index.html">
        <span class="logo-mini" href="">R<b>Z</b></span>
        <span class="logo-lg">
          <img alt="" class="img-fluid" src="@/assets/images/restaurantzone_logo_white_admin.png">
        </span>
      </a>
      <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" @click="toggleMenu">
        <i class="fas fa-bars" v-if="!isMenuToggled"></i>
        <i class="fas fa-xmark" v-if="isMenuToggled"></i>
      </button>
      <div class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0 referral-link">
        <a href="https://therestaurantzone.wufoo.com/forms/z1kiotw40koac4w/" target="_new">
        Get up to a $100 credit on your plan for each friend you invite!</a>
      </div>
      <ul class="navbar-nav ms-auto ms-0 me-3 me-lg-4">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-user fa-fw"></i>&nbsp;
            <span v-if='user.first_name' class="hidden-xs" v-html="user.first_name"></span>
            <span v-else class="hidden-xs">Unknown</span>

          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li class="user-header">
              <i class="fa fa-user fa-5x"></i>
              <p v-if="user.first_name">{{ user.first_name }}</p>
              <p v-else>Unknown</p>
            </li>
            <li>
              <div class="pull-right"><a class="btn btn-default btn-flat" @click="editProfile">Profile</a></div>
              <div class="pull-right"><a class="btn btn-default btn-flat" @click="logout">Logout</a></div>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <RzModal title="Edit Profile" size="modal-lg" ref="rzModal">
      <template v-if="isShowModal" #body>
        <RzUserModal :roles=roles :selectedUser=user :ownProfile=true />
      </template>
    </RzModal>
  </div>

</template>
<script>
import { Modal } from 'bootstrap'

import ApiService from '@/services/ApiService';
import RzUserModal from '@/components/modal/User';
import RzModal from '@/components/common/Modal';


export default {
  name: "RzHeader",
  computed: {},
  components: {
    RzUserModal,
    RzModal
  },
  props: ['user'],
  data(){
    return{
      isMenuToggled: true,
      roles: [],
      isShowModal: false,
    }
  },
  created() {
    ApiService.setAuthenticationToken();
  },
  beforeMount() {
    this.fetchRoles();
  },
  methods: {
    toggleMenu(){
      this.isMenuToggled = !this.isMenuToggled
      this.$emit("toggle-menu", this.isMenuToggled)
    },
    logout(){
      ApiService.get("auth/logout").then((response) => {
        if (response.status == 200){
          localStorage.removeItem('rztoken');
          this.$root.clearCurrentUser();
          window.location.reload();
        }
      })
    },
    fetchRoles(){
      ApiService.get("api/auth/role").then((response) => {
        this.roles = response.data;
      })
    },    
    editProfile() {
      this.isShowModal = true;
      const profileModal = new Modal(this.$refs.rzModal.$refs.rzModal, {});
      profileModal.show();
    },
    closeModal(){
      this.isShowModal = false;
      Modal.getInstance(this.$refs.rzModal.$refs.rzModal)?.hide();
    },
  }
};
</script>
<style scoped>
.navbar{
  background-color: #1f292d !important;
  height: 3.485rem !important;
}
.navbar-brand {
  width: 230px !important;
}

#sidebarToggle{
  color: white !important;
}

.logo {
  display: block;
  float: left;
  height: 3.485rem !important;
  text-align: center;
  padding: 0 12px;
  font-weight: 300;
  overflow: hidden;
  background-color: #1f292d;
  color: #efefef;
  border-bottom: 0 solid transparent;
  margin-left: -16px;
}
.logo .logo-mini {
  font-size: 20px;
  line-height: 53px;
  display: none;
  color: #fff!important;
}
.logo .logo-lg img {
  max-width: 200px;
  float: left;
  margin: 6px 0;
}
.referral-link a{
  text-decoration: none;
  color: white;
}
.referral-link a:hover{
  text-decoration: underline;
}
/* User profile */
.user-header{
  padding: 10px;
  text-align: center;
  background-color: #434d51;
  color: #d9dbdc;
  border: none;
  overflow: visible;
}
.user-footer {
  background-color: #f9f9f9;
  padding: 10px;
}
</style>
