<template>
  <RzResetPassword />
</template>
<script>
import RzResetPassword from '@/components/auth/ResetPassword'

export default {
  name: 'RzResetPasswordView',
  components: {
    RzResetPassword
  }
}
</script>
    
    