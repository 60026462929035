<template>
 <RzHelp />
</template>

<script>
import RzHelp from '@/components/dashboard/Help'

export default {
  name: 'RzHireNowView',
  components: {
    RzHelp
  }
}
</script>