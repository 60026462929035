<template>
  <RzForgotPassword />
</template>
<script>
import RzForgotPassword from '@/components/auth/ForgotPassword'

export default {
  name: 'RzForgotPasswordView',
  components: {
    RzForgotPassword
  }
}
</script>
  
  