import { createRouter as createVueRouter, createWebHistory } from 'vue-router'

import RzLoginView from '@/views/auth/Login'
import RzForgotPasswordView from '@/views/auth/ForgotPassword'
import RzResetPasswordView from '@/views/auth/ResetPassword'
import RzDashboardView from '@/views/dashboard/Dashboard'
import RzHireNowView from '@/views/dashboard/RecruiterAssistance'
import RzResumeView from '@/views/dashboard/ResumeDatabase'
import RzSavedResumeView from '@/views/dashboard/saved-resume/SavedResume'
import RzSavedResumeDetailView from '@/views/dashboard/saved-resume/SavedResumeDetail'
import RzBackgroundCheckView from '@/views/dashboard/BackgroundCheck'
import RzDirectHireView from '@/views/dashboard/DirectHire'
import RzAccountView from '@/views/admin/accounts/Account'
import RzAccountSearchHistoryView from '@/views/admin/accounts/AccountSearchHistory'
import RzAccountUserView from '@/views/admin/accounts/AccountUser'
import RzUserView from '@/views/admin/users/User'
import RzHelp from '@/views/dashboard/Help'

import ApiService from '@/services/ApiService';

const routes = [
  {
    path: '/',
    redirect: {
      name: 'login'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: RzLoginView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: RzForgotPasswordView,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: RzResetPasswordView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    children: [
      {path: '/dashboard', redirect: {name: 'how-it-works'}},
      {path: 'how-it-works', name: 'how-it-works', component: RzDashboardView},
      {path: 'premium-job-ads', name: 'premium-job-ads', component: RzHireNowView},
      {path: 'resume', name: 'resume', component: RzResumeView},
      {path: 'background-check', name: 'background-check', component: RzBackgroundCheckView},
      {path: 'hard-to-fill', name: 'hard-to-fill', component: RzDirectHireView},
      {path: 'help', name: 'help', component: RzHelp},
      {
        path: 'groups',
        name: 'groups',
        children: [
          {path: '', name: 'groups', component: RzSavedResumeView},
          {path: ':id', name: 'group-detail', component: RzSavedResumeDetailView},
        ]
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'admin',
    children: [
      {path: '/admin', redirect: { name: 'accounts' }},
      {
        path: 'account', name: 'accounts',
        children: [
          {path: '', name: 'accounts', component: RzAccountView},
          {
            path: ':id', name: 'account',
            children: [
              {path: 'history', name: 'account-search-history', component: RzAccountSearchHistoryView},
              {path: 'users', name: 'account-users-list', component: RzAccountUserView},
            ]
          },
        ],
      },
      {path: 'user', name: 'users', component: RzUserView},
    ],
    meta: { requiresAuth: true }
  }
]

export const createRouter = (app, publicPath) => {
  const router = createVueRouter({
    history: createWebHistory(publicPath ? publicPath : process.env.BASE_URL),
    routes,
  })
  router.afterEach((to) => {
    const rztoken = JSON.parse(localStorage.getItem('rztoken'));
    if (rztoken === null){
      localStorage.removeItem('rztoken');
      if (window.appRoot !== undefined){
        window.appRoot.clearCurrentUser()
      }
      if (!(['login', 'forgot-password', 'reset-password'].includes(to.name))){
        router.push('/login')
      }
      return;
    }
    ApiService.validateAuthenticationToken(rztoken).then((response) => {
      if (response.status != 200){
        localStorage.removeItem('rztoken');
        if (window.appRoot !== undefined){
          window.appRoot.clearCurrentUser()
        }
        router.push('/login');
      }
      // localStorage.setItem("rzuser", JSON.stringify(response.data))
      if (window.appRoot !== undefined){
        window.appRoot.updateCurrentUser(response.data)
      }
      if (to.name === 'login'){
        router.push('/dashboard')
      }
    }).catch(() => {
      localStorage.removeItem('rztoken');

      if (window.appRoot !== undefined){
        window.appRoot.clearCurrentUser()
      }
    })
    return;
  })
//   router.appInitialized = false;
//   router.initialRoute = null;

//   router.beforeEach((to) => {
//     if (!router.initialRoute) {
//       router.initialRoute = JSON.parse(JSON.stringify(to));
//     }
//     if (!router.appInitialized) {
//       return false;
//     }

//     if ((!app.config.globalProperties.$api.isAuthenticated() || !router.currentUser) && to.name !== 'Login') {
//       return {name: 'Login'};
//     }

//     if ((app.config.globalProperties.$api.isAuthenticated() && router.currentUser) && to.name === 'Login') {
//       return {name: 'Home'};
//     }

//     return true;
//   })

  return router;
}
