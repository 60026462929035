<template>
  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="pull-left hidden-xs"><b>Version</b> 2.1.0</div>
        <div class="text-muted">
          <strong>Copyright © 2023 <a href="">RestaurantZone</a>.</strong> All rights reserved.
        </div>
        <div>
          <a href="#">Privacy Policy</a>
          &middot;
          <a href="#">Terms &amp; Conditions</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "RzFooter",
  computed: {}
};
</script>
