<template>
  <div class="container-fluid px-4">
    <div class="row">
      <div class="col-sm-7">
        <h1 class="mt-4">RZ HireNow: Recruiter Assistance - Receive Qualified Candidates</h1>
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">
            <i>Let our recruiters do the heavy lifting & tap into a network of 10MM+ job-seekers to help you find qualified talent. Click the button below to give us your open job positions & descriptions, so we can send you candidates right away!
            </i>
          </li>
        </ol>
        <p class="text-center start-hiring-now">
          <a
            class="btn btn-xlarge btn-primary btn-padded"
            target="_blank" href="https://therestaurantzone.wufoo.com/forms/zj5urw10fhyftf/"
          >Start Hiring Now</a>
        </p>
      </div>
      <div class="col-sm-5 hiring-image">
        <img class="img-fluid" src="@/assets/images/wufoorms/premium-job-ads.jpg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RzHireNow',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.start-hiring-now {
  margin-top: 5%;
}
.hiring-image {
  margin-top: 7%;
}
</style>
