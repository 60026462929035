<template>
  <div class="container-fluid px-4" v-show="!isLoading">
    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <h1 class="mt-4">Search Resumes</h1>
      </div>
      <div class="col-sm-6 col-lg-6 recruiter-assistance">
        <p class="resume-request mt-4">
          <a class="btn btn-large btn-secondary" href="https://therestaurantzone.wufoo.com/forms/zj5urw10fhyftf/">
            <i class="bi bi-pencil-square"></i> RZ Recruiter Assistance
          </a>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">
            <i class="bi bi-info-circle-fill"></i>
            <i>
              Please note that this database is an aggregation of passive/active candidates.
              Please watch this video <router-link to="/dashboard/how-it-works" href="/dashboard/how-it-works" class="dashboard-link">here</router-link> to see how to search/contact candidates properly.
              If you are unable to find the exact candidate you're looking for please use our free service <router-link to="/dashboard/premium-job-ads" class="dashboard-link">RZ Hire Now</router-link>.
            </i>
          </li>
        </ol>
      </div>
    </div>
    <div class="row resume-filters">
      <div class="col-sm-6">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Keyword</span>
          </div>
          <input type="text" class="form-control" placeholder="Job Position, Title, Skills, or other Keywords" v-model="filter.keyword">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Location</div>
          </div>
          <GMapAutocomplete
            class="form-control"
            placeholder="City, State, or Zip Code"
            @place_changed="setPlace">
          </GMapAutocomplete>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Segment</span>
          </div>
          <select class="form-control" v-model="filter.segment">
            <option selected value="">None</option>
            <option value="Restaurant">Restaurant</option>
            <option value="upscale">Upscale Restaurant</option>
            <option value="fast_food">Fast Food</option>
            <option value="counter">Counter</option>
            <option value="deli_grocery">Deli/Grocery</option>
            <option value="fast_casual">Fast Casual</option>
            <option value="hotel">Hotel</option>
            <option value="hospitality">Hospitality</option>
            <option value="full_service">Full Service</option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-group mb-3">
          <div class="input-group-prepend distance-key" style="width: 30%;">
            <span class="input-group-text" id="basic-addon3">
              Distance&nbsp;<span class="distance-value"> {{ filter.distance }} miles</span>
            </span>
          </div>
          <span class="input-group-text distance-value" style="width: 70%;">
            <input type="range" class="form-range" min="0" max="50" step="5" v-model="filter.distance" />
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button class="btn btn-primary" v-on:click="searchResume">Search Resumes </button>
      </div>
    </div>
    <div class="row table-data">
      <div class="col-sm-12">
        <RzTable :headers=headers :table=tableData>
          <template #tableData >
            <tbody v-if="resumes.length == 0">
							<tr>
								<td :colspan="headers.length">No resumes found.</td>
							</tr>
						</tbody>
            <tbody v-else v-for="(resume, resumeIndex) in resumes" :key="resumeIndex">
              <tr >
                <td><a class="dashboard-link fw-bold" @click="viewResumeBody(resumeIndex)">{{ resume.business_card.name }}</a></td>
                <td>{{ resume.address.state }},{{ resume.address.country }}</td>
                <td class="text-center">
                  <button class="btn btn-info" title="View" @click="viewResume(resume.id)">
                    <i class="fa fa-desktop"></i>
                  </button>&nbsp;
                  <button class="btn btn-info" title="Review" @click="editResume(resume.id)" v-if="isSuperAdmin()">
                    <span class="fa fa-edit"></span>
                  </button>
                </td>
                <td class="text-center">
                  <i
                    class="star-icon"
                    :class="{active: resume.saved.length > 0}"
                    @click="saveResume(resume.id, resumeIndex)"></i>
                </td>
              </tr>
              <tr v-if="selectedIndexes.includes(resumeIndex)" class="cv-body">
                <td :colspan="headers.length">
                  <div v-for="(body, bodyIndex) in getResumeBody(resume)" :key="bodyIndex">
                    <h4>{{ body.title }}</h4>
                    <div v-for="(value, valuesIndex) in body.values" :key="valuesIndex">
                      <p :v-html="value"></p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <PaginationDisplay :record-length="resumes.length" :limit="searchLimit" :offset="searchOffset" :maxResults="-1" v-on:next="navigate(1)" v-on:prev="navigate(-1)" />
          </template>
        </RzTable>
      </div>
    </div>
    <RzModal :title=modalTitle :size=modalSize ref="rzModal">
      <template v-if="isShowModal" #body>
        <RzResumeViewModal v-if="modalTitle == 'View Resume'" :selectedResume=selectedResume :activeGroups=activeGroups />
        <RzResumeEditModal v-if="modalTitle == 'Edit Resume'" :selectedResume=selectedResume />
      </template>
    </RzModal>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'

import ApiService from '@/services/ApiService'
import RzModal from '@/components/common/Modal'
import RzTable from '@/components/common/Table'
import RzResumeViewModal from '@/components/modal/ResumeView'
import RzResumeEditModal from '@/components/modal/ResumeEdit'
import PaginationDisplay from '../common/PaginationDisplay.vue'

export default {
  name: 'RzResumeView',
  components: {
    RzModal,
    RzTable,
    RzResumeViewModal,
    RzResumeEditModal,
    PaginationDisplay
  },
  watch: {},
  
  props: {
    msg: String
  },
  data: function() {
    return {
      selectedResume: {},
      activeGroups: [],
      location: {},
      searchResults: [],
      service: null, // will reveal this later,
      filter: {"keyword": "", "distance": "25", "segment": "",},
      address: "",
      resumes: [],
      // table
      headers: [
        {"name": "Name", "width": "40%", "class": "text-left"},
        {"name": "Location", "width": "30%", "class": "text-left"},
        {"name": "View", "width": "15%", "class": "text-center"},
        {"name": "Saved", "width": "15%", "class": "text-center"},
      ],
      tableData: {
        "class": "table table-responsive table-striped resume-table",
        "name": "resume-database"
      },
      selectedIndexes: [],
      processing: false,
      searchLimit: 50,
      searchOffset: 0,
      isResumeSaved: false,
      // modal data
      isShowModal: false,
      modalTitle: "",
      modalSize: "modal-xl",
      isLoading: false,
    }
  },
  mounted() {},
  computed(){
    this.isResumeSaved = false;
  },
  created(){
  },
  methods: {
    // modal classes
    showModal(){
      this.isShowModal = true;
      var theModal = new Modal(this.$refs.rzModal.$refs.rzModal, {})
      theModal.show();
    },
    closeModal(){
      this.isShowModal = false;
      Modal.getInstance(this.$refs.rzModal.$refs.rzModal)?.hide();
    },
    setPlace(event) {
      if (Object.keys(event).length > 0){
        this.location.location_lat = event.geometry.location.lat()
        this.location.location_lon = event.geometry.location.lng()
        this.location.location_address = event.formatted_address
      }else{
        this.location = {}
      }
    },
    saveResume(resumeId, index){
      this.isResumeSaved = true;
      const payload = {"resume_id": resumeId}
      ApiService
        .post("api/resume/saved", payload)
        .then((response) => { 
          this.resumes[index].saved.push(response.data);
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async searchResume(){
      if (this.filter.keyword.trim() === '' && !this.isSuperAdmin()) {
        alert('Please provide at least one keyword to search for.');
        return;
      }

      this.resumes = []
      this.isLoading = true;
      this.$emit('load-spinner', {isLoading: this.isLoading, message: "Searching Resumes"});
      let query = "";
      // Query
      if (this.filter.keyword != ""){
        query = this.filter.keyword.toLowerCase();
      }
      if (this.filter.segment != ""){
        if (query == ""){
          query = this.filter.segment.toLowerCase()
        }else{
          query = query.concat(",", this.filter.segment.toLowerCase())
        }
      }
      // location filters
      if (Object.keys(this.location).length > 0){
        if (this.filter.distance < 1) {
          this.location.location_distance = "1mi"
        } else {
          this.location.location_distance = this.filter.distance + "mi"
        }
      }

      const params = {
        query: query,
        ranking: "relevance",
        filters: JSON.stringify(this.location),
        limit: this.searchLimit,
        offset: this.searchOffset

      }
      await ApiService
        .get("api/resume", params)
        .then((response) => {
          this.resumes = response.data
        })
        .catch((error) => {
          console.error(error)
          this.$toasted.error("Failed to search resumes.")
        })
      this.isLoading = false;
      this.$emit('load-spinner', {isLoading: this.isLoading, message: ""});
    },
    async getResumeById(resumeId){
      await ApiService
        .get(`api/resume/${resumeId}`)
        .then((response) => {
          this.selectedResume = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getActiveGroups(){
      await ApiService
        .get("api/group?is_active=true")
        .then((response) => {
          this.activeGroups = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async viewResume(resumeId){
      await this.getResumeById(resumeId);
      await this.getActiveGroups();
      this.isShowModal = true;
      this.modalTitle = "View Resume";
      this.showModal();
    },
    async editResume(resumeId){
      if (!this.isSuperAdmin()){
        return;
      }
      await this.getResumeById(resumeId);
      this.isShowModal = true;
      this.modalTitle = "Edit Resume";
      this.showModal();
    },
    viewResumeBody(index){
      let selectedIndex = -1;
      if (this.selectedIndexes.includes(index)){
        selectedIndex = this.selectedIndexes.indexOf(index);
        this.selectedIndexes.splice(selectedIndex, 1)
      }else{
        this.selectedIndexes.push(index);
      }
    },
    getPascalCase(v) {
      return v.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
      });
    },
    getResumeBody(resume){
      let resumeBody = [];
      for (let key in resume.highlight) {
        if (Array.isArray(resume.highlight[key])) {
          let obj = {
            title: this.getPascalCase(key.replace('_', ' ')),
            values: resume.highlight[key]
          };
          resumeBody.push(obj);
        }
      }
      return resumeBody;
    },
    isSuperAdmin() {
      return this.$root.isSuperAdmin();
    },
    navigate(direction) {
      this.searchOffset += this.searchLimit * direction
      if (this.searchOffset < 0) {
        this.searchOffset = 0
      }
      this.searchResume()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.recruiter-assistance{
  text-align: right;
}
#btnGroupAddon {
  width: fit-content;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.bi-info-circle-fill {
  font-size: 1rem;
}
.resume-filters input, select,  span.distance-value{
  border-radius: 4px!important;
}
/* input type range distance */
.form-range::-webkit-slider-thumb {
  background: #23acd3;
}
.form-range::-moz-range-thumb {
  background: #23acd3;
}
.form-range::-ms-thumb {
  background: #23acd3;
}
.distance-value{
  color: #23acd3;
}
i:hover{
  cursor: pointer;
}

.star-icon.active, .star-icon:hover {
  background-image: url(@/assets/images/fill-star.png);
}
</style>
