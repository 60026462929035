<template>
  <RzBackgroundCheck/>
</template>

<script>
import RzBackgroundCheck from '@/components/dashboard/BackgroundCheck'

export default {
  name: 'RzBackgroundCheckView',
  components: {
    RzBackgroundCheck
  }
}
</script>