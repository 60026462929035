<template>
  <RzSavedResume />
</template>

<script>
import RzSavedResume from '@/components/dashboard/saved-resume/SavedResume'

export default {
  name: 'RzSavedResumeView',
  components: {
    RzSavedResume
  }
}
</script>