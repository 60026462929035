<template>
    <!-- vh-100 here-->
    <div class="row vh-100 d-flex align-items-center justify-content-center">
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h1 class="text-center">Forgot Password?</h1>
            <p class="text-muted">
                Please provide the email address that you used when you signed up for your RestaurantZone account.
                We will send you an email that will allow you to reset your password.
            </p>
            <form @submit.prevent="forgotPassword">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address <span class="required">*</span></label>
                <input type="email" class="form-control" placeholder="Email address" v-model="auth.username" required>
              </div>
              <button type="submit" class="btn btn-primary">Send me password reset email</button>
            </form><br>
            <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorMessage != ''">
              <strong>{{ errorCode }}</strong> {{ errorMessage }}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="successMessage != ''">
              <strong>{{ successMessage }}</strong>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-6 text-left">
                <router-link class="nav-link" to="/login" active-class="active">
                    Already have an account?
                </router-link>
              </div>
              <div class="col-6 text-left">
                <a class="nav-link" href="https://therestaurantzone.wufoo.com/forms/x1s2jpw01e54gkd/" target="_blank">
                  Don't have an account?
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </template>
  <script>
  import { reactive } from "vue";
  import ApiService from "@/services/ApiService";
  
  export default {
    name: 'RzForgotPassword',
    data(){
      return {
        errorMessage: "",
        successMessage: "",
        errorCode: "",
        auth: reactive({
          username: "",
        })
      }
    },
    async created(){
    },
    methods: {
      async forgotPassword(){
        this.successMessage = this.errorMessage = '';
        await ApiService.post("auth/sendPasswordLink", {
          base_url: process.env.VUE_APP_API_URL + "reset-password/",
          username: this.auth.username
        }).then(() => {
          this.successMessage = "Successfully sent reset password email."
        }).catch((error) =>{
          console.log(error)
          this.errorCode = error.code;
          this.errorMessage = error.message;
        })
      }
    }
  }
  
  </script>
  <style scoped>
  .form-group, label{
    margin-bottom: 15px;
  }
  .btn {
    width: 100%;
  }
  span.required {
    color: red;
  }
  .card-footer {
    background: #fefefe;
    padding: 30px 45px;
  }
  .nav-link {
    color: #23acd3 !important;
  }
  </style>
  