<template>
  <RzAccount />
</template>

<script>
import RzAccount from '@/components/admin/accounts/Account'

export default {
  name: 'RzAccountView',
  components: {
    RzAccount
  }
}
</script>