<template>
  <div class="container">
    <div class="row resume-heading">
      <span class="muted-text">Id: {{ resume.id }}</span>
    </div>
    <div class="row resume-data">
      <div class="col-lg-4">
        <form @submit.prevent="updateResumeFields">
          <legend class="text-center">Resume Data</legend>
          <fieldset class="form-group">
            <legend class="business-card">Business card</legend>
            <div class="mb-1">
              <label for="field">Name: </label>
              <input type="text" v-model=resume.business_card.name>
            </div>
            <div class="mb-1">
              <label for="field">Email: </label>
              <input type="text" v-model=resume.business_card.email>
            </div>
            <div class="mb-1">
              <label for="field">Phone: </label>
              <input type="text" v-model=resume.business_card.phone>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <legend class="address">Address</legend>
            <div class="mb-1">
              <label for="field">City: </label>
              <input type="text" v-model=resume.address.city>
            </div>
            <div class="mb-1">
              <label for="field">State: </label>
              <input type="text" v-model=resume.address.state>
            </div>
            <div class="mb-1">
              <label for="field">Country: </label>
              <input type="text" v-model=resume.address.country>
            </div>
            <div class="mb-1">
              <label for="field">Zip: </label>
              <input type="text" v-model=resume.address.zip>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <legend class="location">Location</legend>
            <div class="mb-1">
              <label for="field">Lat: </label>
              <input type="text" v-model=resume.location.lat>
            </div>
            <div class="mb-1">
              <label for="field">Lon: </label>
              <input type="text" v-model=resume.location.lon>
            </div>
          </fieldset>
          <div class="d-grid gap-2" v-if="!isSaving">
            <button type="submit" class="btn btn-info btn-block">Update Resume Fields</button>
          </div>
          <div class="text-center d-grid gap-2" v-if="isSaving">
            <button class="btn btn-info btn-block" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="text-white">Updating...</span>
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-8 overflow-auto" style="max-height: 600px;">
        <fieldset>
          <legend class="text-center">Uploaded Resume</legend>
          <vue-pdf-embed :source="resume.download_url" />
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: "RzResumeEditModal",
  components: {
    VuePdfEmbed,
  },
  props: {
    selectedResume: {
      type: Object,
      default: (() => {})
    }
  },
  data(){
    return {
      resume: {},
      isSaving: false,
      errorMessage: "",
    }
  },
  created() {
    this.resume = this.selectedResume;
  },
  methods: {
    async updateResumeFields(){
      this.isSaving = true;
      await ApiService
        .put(`api/resume/${this.resume.id}`, (this.resume))
        .then(() => {
          this.isSaving = false;
        })
        .catch((error) => {
          this.isSaving = false;
          console.error(error)
          if (error.response.data.internal_error !== undefined){
            alert(error.response.data.internal_error)
          }else{
            alert("Something went wrong. Please try again later.")
          }
        })
    }
  }
}
</script>
<style scoped>
.resume-heading{
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}
legend.business-card, legend.address, legend.location{
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border-bottom: 1px solid #e5e5e5;
}

div.gap-2 {
  cursor: pointer;
  margin-top: 10px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
</style>
    