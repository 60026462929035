<template>
	<div class="container-fluid px-4">
		<div class="row">
      <div class="col-sm-9 col-lg-9">
        <h1 class="mt-4">{{ account.account_name }} - Users</h1>
      </div>
      <div class="col-sm-3 col-lg-3 add-user-to-account">
        <p class="resume-request mt-4">
          <a class="btn btn-large btn-secondary" @click="addUserToAccount()">
            <i class="bi bi-person-fill"></i> Add User To Account
          </a>
        </p>
      </div>
    </div>
		<div class="row">
			<div class="col-sm-12">
				<RzTable :headers=headers :table=tableData >
					<template #tableData >
						<tbody v-if="accountUsers.length > 0">
							<tr v-for="(account, index) in accountUsers" :key="index">
								<td>
									{{  account.user.first_name  }}
								</td>
								<td>{{  account.user.last_name  }}</td>
								<td>{{  account.user.email  }}</td>
								<td>{{  account.user.phone  }}</td>
								<td class="text-center">
									<i v-if="account.user.is_active" class="fa fa-square-check"></i>
									<i v-if="!account.user.is_active" class="fa fa-square"></i>
								</td>
								<td class="text-center">
									<span>
										<a class="btn btn-info" title="View" @click="viewUser(account.user)">
											<span class="fa fa-edit"></span>
										</a>&nbsp;
										<a class="btn btn-info" title="Delete user" @click="deleteAccountUser(account)">
											<span class="fa fa-trash"></span>
										</a>
									</span>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="accountUsers.length == 0">
							<tr>
								<td :colspan="headers.length">No users found for account.</td>
							</tr>
						</tbody>
					</template>
				</RzTable>
			</div> 
		</div>
		<RzModal :title=modalTitle :size=modalSize ref="rzModal">
      <template v-if="isShowModal" #body>
				<template v-if="modalTitle == 'Edit Profile'">
					<RzUserModal :roles=roles :selectedUser=selectedUser v-on:saved="onUserSaved" />
				</template>
				<template v-else>
					<RzAccountUserModal :account=account v-on:saved="onUserSaved" />
				</template>
      </template>
    </RzModal>
	</div>
</template>
    
<script>
import { Modal } from 'bootstrap'

import ApiService from '@/services/ApiService'

import RzTable from '@/components/common/Table'
import RzUserModal from '@/components/modal/User'
import RzAccountUserModal from '@/components/modal/AccountUser'
import RzModal from '@/components/common/Modal'

export default {
	name: 'RzAccountUser',
	components: {
		RzTable,
		RzModal,
		RzUserModal,
		RzAccountUserModal,
	},
	props: {},
	data() {
		return {
			account: {},
			accountUsers: [],
			tableData: {
				"class": "table table-responsive table-striped users-table",
				"name": "users-list"
			},
			headers: [
				{"name": "First Name", "width": "20%", "class": "text-left"},
				{"name": "Last Name", "width": "20%", "class": "text-left"},
				{"name": "Email", "width": "25%", "class": "text-left"},
				{"name": "Phone", "width": "15%", "class": "text-left"},
				{"name": "Active", "width": "5%", "class": "text-center"},
				{"name": "Actions", "width": "15%", "class": "text-center"},
			],
			roles: [],
			selectedUser: {},
			action: "",
      // modal data
      isShowModal: false,
			modalTitle: "",
      modalSize: "modal-lg",
    }
	},
	beforeMount() {
		this.fetchRoles();
		this.fetchAccount(this.$route.params.id)
		this.fetchAccountUsers(this.$route.params.id);
	},
	methods: {
		async fetchRoles(){
      await ApiService
        .get("api/auth/role")
        .then((response) => {
          this.roles = response.data;
        })
        .catch((error) => {
          console.error(error)
        })
    },
		async fetchAccount(accountId){
			await ApiService
				.get(`api/account/${accountId}`)
				.then((response) => {
					this.account = response.data;
				})
				.catch((error) => {
					console.error(error)
				})
		},
		async fetchAccountUsers(accountId){
			await ApiService
				.get(`api/account/user?account_id=${accountId}`)
				.then((response) => {
					this.accountUsers = response.data;
				})
				.catch((error) => {
					console.error(error)
				})
		},
		async addUserToAccount(){
			this.modalTitle = `Add Profile to ${this.account.account_name}`;
			// await ApiService.post()
			this.toggleModal();
		},
		toggleModal(){
      this.isShowModal = true;
      const userModal = new Modal(this.$refs.rzModal.$refs.rzModal, {});
      userModal.show();
    },
    closeModal(){
      this.isShowModal = false;
      Modal.getInstance(this.$refs.rzModal.$refs.rzModal)?.hide();
    },
    viewUser(accountUser){
			// check if is_active is boolean
			this.modalTitle = "Edit Profile";
			const is_active = accountUser.is_active
			accountUser.is_active = is_active.toLowerCase() == "true" ? true : false;
			this.selectedUser = accountUser;
      this.toggleModal();
    },
		deleteAccountUser(ac){
			if(confirm(`Are you sure you want remove user ${ac.user.first_name} from this account?`)){
				ApiService
					.delete(`api/account/user/${ac.id}`)
					.then(() => {
						this.fetchAccountUsers(this.$route.params.id);
						this.$toasted.success("User removed from account successfully.")
					})
					.catch((error) => {
						console.error(error)
						this.$toasted.error("Failed to remove user from account.")
					})
			}
		},
		onUserSaved(){
			this.fetchAccountUsers(this.$route.params.id);
		}
	}
}
</script>
<style scoped>
.add-user-to-account{
  text-align: right;
}
i.fa, i.fa:hover{
  color: #0dcaf0;
}
</style>
    