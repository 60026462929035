<template>
  <!-- Modal -->
  <form @submit.prevent="saveGroup">
    <div class="mb-3">
      <label for="folderName" class="form-label">Folder Name:</label>
      <input
        type="text" name="folder_name" class="form-control"
        v-model="group.name" placeholder="Folder Name" required>
    </div>
    <div class="mb-3">
      <label for="remainingRollTimes" class="form-label">Folder Note:</label>
      <textarea class="form-control" placeholder="Leave a note here" rows="10" v-model=group.notes></textarea>
    </div>
    <div class="d-grid gap-2">
      <button type="submit" class="btn btn-info btn-block">Save</button>
    </div>
  </form>
</template>
<script>
import { reactive } from "vue";
import ApiService from "@/services/ApiService";

export default {
  name: "RzGroupModal",
  components: {},
  props: {
    selectedGroup: Object,
  },
  data(){
    return {
      isLoading: false,
      group: reactive({
        id: "",
        name: "",
        notes: "",
      })
    }
  },
  created() {
    if (Object.keys(this.selectedGroup).length > 0){
      this.group = {
        id: this.selectedGroup.id,
        name: this.selectedGroup.name,
        notes: this.selectedGroup.notes,
      }
    }
  },
  methods: {
    async saveGroup(){
      if (this.group.id == ""){
        this.$parent.loadSpinner({isLoading: true, message: "Adding Folder..."})
        await ApiService
          .post("api/group", {
            name: this.group.name,
            notes: this.group.notes
          })
          .then(() => {
            this.$parent.closeModal();
          })
          .catch((error) => {
            console.log(error)
          })
          this.$parent.loadSpinner({isLoading: false, message: ""})
      }else{
        await ApiService
          .put(`api/group/${this.group.id}`, this.group)
          .then(()=>{})
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>
<style scoped>
</style>
  