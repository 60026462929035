<template>
  <tfoot>
    <tr class="paginator" v-if="recordLength > 0 || offset > 0">
      <td colspan="99">
        <div class="text-center">
          <span>
            <a class="prev a1" v-if="offset > 0" v-on:click.prevent="$emit('prev')">‹‹&nbsp;Previous</a>
            &nbsp;
            <span class="current">
              Showing {{ offset + 1 }} to {{ offset + recordLength }}
            </span>
            &nbsp;
            <a class="next a1" v-if="recordLength >= limit && (maxResults == -1 || offset + records.length < maxResults)" v-on:click.prevent="$emit('next')">Next&nbsp;››</a>
          </span>
        </div>
      </td>
    </tr>
  </tfoot>
</template>

<script>
export default {
  props: ['recordLength', 'limit', 'offset', 'maxResults'],
}
</script>