<template>
<!-- Modal -->
<form @submit.prevent="saveAccount">
  <div class="mb-3">
    <label for="accountName" class="form-label">Account Name:</label>
    <input
      type="text" name="account_name" class="form-control"
      v-model="account.account_name" placeholder="Account Name" required>
    <div class="alert alert-danger" v-if="getFormError('account_name')">{{ getFormError('account_name') }}</div>
  </div>
  <div class="mb-3">
    <label for="Role" class="form-label">Account Class:</label>
    <select class="form-select" name="role" v-model=account.account_class required>
      <option value="" disabled selected>Select An Account Class</option>
      <option v-for="(accountClass, index) in accountClasses" :key=index :value="`${accountClass.id}`">{{ accountClass.notes }}</option>
    </select>
    <div class="alert alert-danger" v-if="getFormError('account_class')">{{ getFormError('account_class') }}</div>
  </div>
  <template v-if="Object.keys(selectedAccount).length > 0">
    <div class="mb-3">
      <label for="remainingRollTimes" class="form-label">Remaining Roll Times:</label>
      <input
        type="number" name="remaining_roll_times" class="form-control"
        v-model="account.remaining_roll_times" required>
      <div class="alert alert-danger" v-if="getFormError('remaining_roll_times')">{{ getFormError('remaining_roll_times') }}</div>
    </div>
    <div class="mb-3">
      <label for="remainingSaveCount" class="form-label">Remaining Save Count:</label>
      <input
        type="number" name="remaining_save_count" class="form-control"
        v-model="account.remaining_save_count" required>
      <div class="alert alert-danger" v-if="getFormError('remaining_save_count')">{{ getFormError('remaining_save_count') }}</div>
    </div>
    <div class="mb-3">
      <label for="remainingSearchCount" class="form-label">Remaining Search Count:</label>
      <input
        type="number" name="remaining_search_count" class="form-control"
        v-model="account.remaining_search_count" required>
      <div class="alert alert-danger" v-if="getFormError('remaining_search_count')">{{ getFormError('remaining_search_count') }}</div>
    </div>
    <div class="mb-3">
      <label for="remainingViewCount" class="form-label">Remaining View Count:</label>
      <input
        type="number" name="remaining_view_count" class="form-control"
        v-model="account.remaining_view_count" required>
      <div class="alert alert-danger" v-if="getFormError('remaining_view_count')">{{ getFormError('remaining_view_count') }}</div>
    </div>
  </template>
  <div class="mb-3">
    <span class="float-start">
      <button type="submit" class="btn btn-info" :disabled="formProcessing" v-if="Object.keys(selectedAccount).length > 0">Save</button>
      <button type="submit" class="btn btn-info" :disabled="formProcessing" v-else>Add</button>
    </span>
    <span class="float-end">
      <button type="button" class="btn btn-info" :disabled="formProcessing" v-if="Object.keys(selectedAccount).length > 0" v-on:click.prevent="resetCounts()">Reset Counts</button>
    </span>
  </div>
</form>
</template>
<script>
import { reactive } from "vue";
import ApiService from "@/services/ApiService";
import ModalFormMixin from "@/mixins/ModalFormMixin";


export default {
  name: "RzAccountModal",
  components: {},
  mixins: [ModalFormMixin],
  props: {
    accountClasses: Array,
    selectedAccount: Object,
  },
  data(){
    return {
      account: reactive({
        id: "",
        account_name: "",
        account_class: "",
        remaining_roll_times: 0,
        remaining_save_count: 0,
        remaining_search_count: 0,
        remaining_view_count: 0
      }),
    }
  },
  created() {
    if (Object.keys(this.selectedAccount).length > 0){
      this.account = {
        id: this.selectedAccount.id,
        account_name: this.selectedAccount.account_name,
        account_class: `${this.selectedAccount.account_class}`,
        remaining_roll_times: this.selectedAccount.remaining_roll_times,
        remaining_save_count: this.selectedAccount.remaining_save_count,
        remaining_search_count: this.selectedAccount.remaining_search_count,
        remaining_view_count: this.selectedAccount.remaining_view_count,
      }
    }
  },
  methods: {
    saveAccount() {
      if (this.formProcessing) {
        return
      }
      this.formProcessing = true

      this.formErrors = {}

      this.$emit("load-modal-spinner", {isLoading: true, message: "Adding Account"})
      if (Object.keys(this.selectedAccount).length == 0){
        const payload = {
          account_class: this.account.account_class,
          account_name: this.account.account_name
        }
        ApiService
          .post("api/account", payload)
          .then(this.onSaveSuccess)
          .catch(this.onSaveError)
      } else {
        ApiService
          .put(`api/account/${this.account.id}`, this.account)
          .then(this.onSaveSuccess)
          .catch(this.onSaveError)
      }
    },
    resetCounts() {
      const account_class = this.accountClasses.filter((accountClass) => {
        return accountClass.id == this.account.account_class
      })[0]

      this.account.remaining_view_count = account_class.max_views;
      this.account.remaining_save_count = account_class.max_saves;
      this.account.remaining_search_count = account_class.max_searches;
    }

  }
}
</script>
