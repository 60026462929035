<template>
	<RzAccountUser />
</template>
    
<script>
import RzAccountUser from '@/components/admin/accounts/AccountUser'

export default {
	name: 'RzAccountUserView',
	components: {
		RzAccountUser
	}
}
</script>