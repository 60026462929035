<template>
  <RzDashboard/>
</template>

<script>
import RzDashboard from '@/components/dashboard/Dashboard'

export default {
  name: 'RzDashboardView',
  components: {
    RzDashboard
  }
}
</script>