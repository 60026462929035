<template>
  <div class="table-responsive">
    <table :class="table.class">
      <thead>
        <tr >
          <th v-for="(header, index) in headers" :key="index" :width="header.width" :class="header.class">{{ header.name }}</th>
        </tr>
      </thead>
      <slot name="tableData" />
    </table>
  </div>
  
</template>
<script>
export default {
  name: "RzTable",
  props: {
    headers: Array,
    data: Array,
    table: Object,
    additionalData: Array,
  },
  computed: {},
  methods: {}
};
</script>
<style scoped>

</style>
