<template>
  <RzHireNow/>
</template>

<script>
import RzHireNow from '@/components/dashboard/RecruiterAssistance'

export default {
  name: 'RzHireNowView',
  components: {
    RzHireNow
  }
}
</script>