<template>
<div class="row">
  
  <!-- <div class="col-md-12 col-lg-8">
    <br />
    <h3 style="margin: 0px 30px;"><span class="pull-left">Resume</span>  <button class="btn btn-info pull-right">Download</button></h3>
    <div class="clearfix"> </div>
    <div class="resume-preview-area">
      <div id="preview-area">
        <iframe class="embed-responsive-item" type="application/pdf" :src=selectedResume.download_url></iframe> 
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="mb-3">
      <label for="note" class="form-label">Notes:</label>
      <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
    </div>
    <div class="mb-3">
      <label for="Role" class="form-label">Account Class:</label>
      <select class="form-select" name="role">
        <option>Option 1</option>
        <option>Option 2</option>
      </select>
    </div>
  </div> -->
  <div class="col-lg-8">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <span class="float-start">
            <legend>Resume</legend>
          </span>
          <span class="float-end">
            <button type="button" class="btn btn-info" @click="downloadResume">Download</button>
          </span>
        </div>
      </div>
    </div>
    <div class="row overflow-auto" style="max-height: 600px;">
      <div class="col-lg-12">
        <vue-pdf-embed :source="selectedResume.download_url" />
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <form v-if="!isAddNewGroup" @submit.prevent="saveResume">
      <fieldset>
        <legend class="text-center">Add Notes</legend>
        <div class="alert alert-danger alert-dismissible" role="alert" v-if="errorMessage != ''">
          <div>{{ errorMessage }}</div>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="mb-3">
          <label for="note" class="form-label">Notes:</label>
          <textarea class="form-control" placeholder="Leave a note here" rows="10" v-model=resume.notes></textarea>
        </div>
        <div class="mb-3">
          <div class="float-end"><a class="dashboard-link" @click="isAddNewGroup = !isAddNewGroup">New</a></div>
          <label for="Role" class="form-label">Group:</label>
          <select class="form-select" name="role" v-model=resume.resume_group_id>
            <option selected disabled>Choose a Group</option>
            <option v-for="(group, index) in activeGroups" :key=index :value=group.id>{{ group.name }}</option>
          </select>
        </div>
        <div class="d-grid gap-2" v-if="!isSaving">
          <button type="submit" class="btn btn-info btn-block">Save</button>
        </div>
      </fieldset>
    </form>
    <form v-if="isAddNewGroup" @submit.prevent="saveGroup">
      <fieldset class="form-group">
        <h5>Group : (optional)</h5>
        <div class="mb-3">
          <div class="float-end"><a class="dashboard-link" @click="isAddNewGroup = !isAddNewGroup">Cancel</a></div>
          <label for="groupName" class="form-label">Group Name:</label>
          <input type="text" class="form-control" v-model=group.name />
        </div>
        <div class="mb-3">
          <label for="note" class="form-label">Group Notes:</label>
          <textarea class="form-control" placeholder="" rows="5" v-model=group.notes></textarea>
        </div>
        <div class="d-grid gap-2" v-if="!isSaving">
          <button type="submit" class="btn btn-info btn-block">Save</button>
        </div>
      </fieldset>
    </form>
    <div class="text-center d-grid gap-2" v-if="isSaving">
      <button class="btn btn-info btn-block" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="text-white">Saving...</span>
      </button>
    </div>
    
  </div>
</div>
</template>
<script>
import { reactive } from "vue";
import ApiService from "@/services/ApiService";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: "RzResumeViewModal",
  components: {
    VuePdfEmbed,
  },
  props: {
    selectedResume: Object,
    activeGroups: Array,
  },
  data(){
    return {
      isAddNewGroup: false,
      isSaving: false,
      errorMessage: "",
      group: reactive({
        name: "",
        notes: "",
      }),
      resume: reactive({
        notes: "",
        resume_group_id: [],
        resume_id: this.selectedResume.id,
      })
    }
  },
  created() {},
  methods: {
    async saveResume(){
      this.isSaving = true;
      await ApiService
        .post("api/resume/saved", this.resume)
        .then(() => {
          this.isSaving = false;
        })
        .catch((error) =>{
          this.isSaving = false;
          console.log(error.response.data.message)
          if (error.response.data.message.message !== undefined){
            this.errorMessage = error.response.data.message.message
          }else{
            this.errorMessage = "Something went wrong. Please try again later."
          }
        })
    },
    async saveGroup(){
      this.isSaving = true;
      await ApiService
        .post("api/group", this.group)
        .then(() => {
          this.isSaving = false;
        })
        .catch((error) =>{
          this.isSaving = false;
          if (error.response.data.message.message !== undefined){
            this.errorMessage = error.response.data.message.message
          }else{
            this.errorMessage = "Something went wrong. Please try again later."
          }
        })
    },
    async downloadResume(){
      window.open(this.selectedResume.download_url, '_blank', 'noreferrer');
    }
  }
}
</script>
<style scoped>
.resume-preview-area {
  text-align: center;
  border: 1px solid #777;
  padding: 0px;
  margin: 20px 30px 0;
  box-shadow: 15px 15px 5px #a6a6a6;
  min-height: 500px;
  max-height: 500px;
  overflow-y: none;
}
.preview-area iframe {
  width: 100%;
  min-height: 500px;
  max-height: 500px;
  border: none;
}
</style>
    