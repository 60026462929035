<template>
  <!-- vh-100 here-->
  <div class="row vh-100 d-flex align-items-center justify-content-center">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h1 class="text-center">Login</h1>
          <form @submit.prevent="login">
            <div class="form-group">
              <label for="exampleInputEmail1">Email address <span class="required">*</span></label>
              <input type="email" class="form-control" placeholder="Email address" v-model="auth.username" required :disabled="processing">
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password <span class="required">*</span></label>
              <input type="password" class="form-control" placeholder="Password" v-model="auth.password" required :disabled="processing">
            </div>
            <button type="submit" class="btn btn-primary" :disabled="processing">
              <i class="fa" :class="{'fa-sign-in': !processing, 'fa-spin fa-spinner': processing}"></i>
              Login
            </button>
            <div class="alert alert-danger" v-if="errorMessage">{{ errorMessage }}</div>
          </form>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6 text-left">
              <router-link class="nav-link" to="/forgot-password" active-class="active">
                Forgot Password?
              </router-link>
            </div>
            <div class="col-6 text-left">
              <a class="nav-link" href="https://therestaurantzone.wufoo.com/forms/x1s2jpw01e54gkd/" target="_blank">
                Don't have an account?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import { reactive } from "vue";
import ApiService from "@/services/ApiService";

export default {
  name: 'RzLogin',
  data(){
    return {
      auth: reactive({
        username: "",
        password: "",
      }),
      processing: false,
      errorMessage: "",
    }
  },
  async created(){
  },
  methods: {
    login(){
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.errorMessage = "";

      ApiService
        .post('auth/', this.auth)
        .then((response) => {
          this.processing = false;
          let data = response.data
          localStorage.setItem('rztoken', JSON.stringify({"type": "JWT", "token": data.access_token}))
          window.location.reload();
        })
        .catch((error) => {
          this.processing = false;
          console.log(error)

          if (error.response.status >= 400 && error.response.status < 500){
            this.errorMessage = "Invalid username or password.";
          } else if (error.response.data && error.response.data.description){
            this.errorMessage = error.response.data.description;
          } else {
            this.errorMessage = "An unknown error occurred whilst logging in.";
          }
        })

    }
  }
}

</script>
<style scoped>
.form-group, label{
  margin-bottom: 15px;
}
.btn {
  width: 100%;
}
span.required {
  color: red;
}
.card-footer {
  background: #fefefe;
  padding: 30px 45px;
}
.nav-link {
  color: #23acd3 !important;
}
</style>
