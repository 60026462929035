<template>
  <main class="sb-nav-fixed">
    <div class="container" v-if="!isLoggedIn">
      <router-view :key="$route.path" @load-spinner="loadSpinner"/>
    </div>
    <div id="layoutSidenav" v-if="isLoggedIn">
      <RzHeader @toggle-menu="onToggleMenu" :user="currentUser"/>
      <div id="layoutSidenav_nav" v-if="isToggleMenu">
        <RzSideBar :user="currentUser" />
      </div>
      <div id="layoutSidenav_content"  v-if="isToggleMenu" style="padding-left: 225px;">
        <RzSpinner v-if="isLoading" :message=loadingMessage></RzSpinner>
        <router-view :key="$route.path" @load-spinner="loadSpinner"/>
        <RzFooter />
      </div>
      <div id="layoutSidenav_content"  v-if="!isToggleMenu" style="padding-left: 0px;">
        <RzSpinner v-if="isLoading" :message=loadingMessage></RzSpinner>
        <router-view :key="$route.path" @load-spinner="loadSpinner"/>
        <RzFooter />
      </div>
    </div>
  </main>
</template>

<script>
import ApiService from "@/services/ApiService";

import RzSpinner from "@/components/common/Spinner"
import RzHeader from "@/components/Header";
import RzSideBar from "@/components/SideBar";
import RzFooter from "@/components/Footer";

export default {
  name: "App",
  components: {
    RzHeader,
    RzSideBar,
    RzFooter,
    RzSpinner
  },
  beforeMount(){},
  created (){
    ApiService.setAuthenticationToken();
    this.isLoggedIn = JSON.parse(localStorage.getItem('rztoken')) ? true : false;
  },
  data() {
    return {
      isLoggedIn: false,
      isLoading: false,
      loadingMessage: "",
      isToggleMenu: true,
      currentUser: {},
    }
  },
  watch: {},
  methods: {
    onToggleMenu(isShowMenu){
      this.isToggleMenu = isShowMenu;
    },
    loadSpinner({isLoading, message}){
      this.isLoading = isLoading;
      this.loadingMessage = message;
    },
    updateCurrentUser(data) {
      this.currentUser = data;
    },
    clearCurrentUser() {
      this.currentUser = {};
    },
    isSuperAdmin() {
      if (this.currentUser !== null && this.currentUser.role !== undefined) {
        return this.currentUser.role.super_admin;
      }
      return false;
    }
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
}
.wrapper {
  width: 100%;
  height: 100%;
}
.admin {
  font-family: Muli Light;
  font-size: 16px;
  line-height: 34px;
  color: #3e3e3e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}
#layoutSidenav_nav {
  width: 230px !important;
}
</style>
