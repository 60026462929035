export default {
  data(){
    return {
      formProcessing: false,
      formErrors: {}
    }
  },
  methods: {
    onSaveSuccess(){
      this.formProcessing = false
      this.$toasted.success("Record saved successfully")
      this.$emit("load-modal-spinner", {isLoading: false, message: ""})
      this.$emit("saved");
    },
    onSaveError(error){
      this.formProcessing = false
      if (error.response.status == 400){
        this.formErrors = error.response.data
        this.$toasted.error("Please correct the errors in the form")
      } else {
        this.$toasted.error("An error occurred while saving this record")
      }
      this.$emit("load-modal-spinner", {isLoading: false, message: ""})

      console.log(error)
    },
    getFormError(field){
      if (this.formErrors['message'] && this.formErrors['message']['key'] && this.formErrors['message']['key'] == field){
        return this.formErrors['message']['message']
      }
      if (this.formErrors[field]){
        return this.formErrors[field][0]
      }
      return null;
    }
  }
}