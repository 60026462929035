<template>
  <!-- vh-100 here-->
  <div class="row vh-100 d-flex align-items-center justify-content-center">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h1 class="text-center">Reset Password</h1>
          <form @submit.prevent="resetPassword">
            <div class="form-group">
              <label for="exampleInputEmail1">Password <span class="required">*</span></label>
              <input type="password" class="form-control" placeholder="Password" v-model="auth.password" required>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Password Confirmation<span class="required">*</span></label>
              <input type="password" class="form-control" placeholder="Confirm Password" v-model="auth.confirm_password" required>
            </div>
            <button type="submit" class="btn btn-primary">Reset Password</button>
          </form><br>
          <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="hasError">
            <strong>{{message}}</strong> 
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="!hasError && message != ''">
            <strong>{{ message }}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6 text-left">
              <router-link class="nav-link" to="/login" active-class="active">
                  Already have an account?
              </router-link>
            </div>
            <div class="col-6 text-left">
              <a class="nav-link" href="https://therestaurantzone.wufoo.com/forms/x1s2jpw01e54gkd/" target="_blank">
                Don't have an account?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
</template>
<script>
import { reactive } from "vue";
import ApiService from "@/services/ApiService";

export default {
  name: 'RzResetPassword',
  data(){
    return {
      resetPasswordToken: "",
      message: "",
      hasError: false,
      auth: reactive({
        password: "",
        confirm_password:"",
      })
    }
  },
  async created(){
    this.resetPasswordToken = this.$route.params.token;
  },
  methods: {
    scorePassword(pass) {
      let score = 0;
      if (!pass) {
          return score;
      }

      let letters = new Object();
      for (let i = 0; i < pass.length; i++) {
          letters[pass[i]] = (letters[pass[i]] || 0) + 1;
          score += 5.0 / letters[pass[i]];
      }

      let variations = {
          digits: /\d/.test(pass),
          lower: /[a-z]/.test(pass),
          upper: /[A-Z]/.test(pass),
          nonWords: /\W/.test(pass),
      };

      let variationCount = 0;
      for (let check in variations) {
          variationCount += (variations[check] === true) ? 1 : 0;
      }
      score += (variationCount - 1) * 10;
      if (score > 100) {
          score = 100;
      }

      return score;
    },

    async resetPassword(){
      if (this.auth.password !== this.auth.confirm_password){
        this.message = 'Passwords do not match';
        this.hasError = true;
        return;
      }
      let password_score = this.scorePassword(this.auth.password)
      if (password_score < 33) {
        this.message = 'This password is too weak, please provide a stronger one.';
        this.hasError = true;
        return;
      }
      await ApiService.post("auth/resetPassword/" + encodeURIComponent(this.resetPasswordToken), {
        password: this.auth.password
      }).then(() => {
        this.hasError = false;
        this.message = "Successfully reset password."
      }).catch((error) =>{
        this.hasError = true;
        this.message = error.message;
      })
    }
  }
}

</script>
<style scoped>
.form-group, label{
  margin-bottom: 15px;
}
.btn {
  width: 100%;
}
span.required {
  color: red;
}
.card-footer {
  background: #fefefe;
  padding: 30px 45px;
}
.nav-link {
  color: #23acd3 !important;
}
</style>
